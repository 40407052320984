/* poppins-100 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/poppins/poppins-v15-latin-100.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/poppins/poppins-v15-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/poppins/poppins-v15-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-100.woff') format('woff'), /* Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/poppins/poppins-v15-latin-100.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-100italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  src: url('./fonts/poppins/poppins-v15-latin-100italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/poppins/poppins-v15-latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/poppins/poppins-v15-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-100italic.woff') format('woff'), /* Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/poppins/poppins-v15-latin-100italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-200italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  src: url('./fonts/poppins/poppins-v15-latin-200italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/poppins/poppins-v15-latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/poppins/poppins-v15-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-200italic.woff') format('woff'), /* Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/poppins/poppins-v15-latin-200italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-200 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/poppins/poppins-v15-latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/poppins/poppins-v15-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/poppins/poppins-v15-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-200.woff') format('woff'), /* Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/poppins/poppins-v15-latin-200.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-300 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/poppins/poppins-v15-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/poppins/poppins-v15-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/poppins/poppins-v15-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-300.woff') format('woff'), /* Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/poppins/poppins-v15-latin-300.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-300italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  src: url('./fonts/poppins/poppins-v15-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/poppins/poppins-v15-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/poppins/poppins-v15-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/poppins/poppins-v15-latin-300italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/poppins/poppins-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/poppins/poppins-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/poppins/poppins-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/poppins/poppins-v15-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  src: url('./fonts/poppins/poppins-v15-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/poppins/poppins-v15-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/poppins/poppins-v15-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/poppins/poppins-v15-latin-italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-500italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  src: url('./fonts/poppins/poppins-v15-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/poppins/poppins-v15-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/poppins/poppins-v15-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-500italic.woff') format('woff'), /* Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/poppins/poppins-v15-latin-500italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-500 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/poppins/poppins-v15-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/poppins/poppins-v15-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/poppins/poppins-v15-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-500.woff') format('woff'), /* Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/poppins/poppins-v15-latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-600 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/poppins/poppins-v15-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/poppins/poppins-v15-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/poppins/poppins-v15-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-600.woff') format('woff'), /* Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/poppins/poppins-v15-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-600italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  src: url('./fonts/poppins/poppins-v15-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/poppins/poppins-v15-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/poppins/poppins-v15-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-600italic.woff') format('woff'), /* Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/poppins/poppins-v15-latin-600italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-700 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/poppins/poppins-v15-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/poppins/poppins-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/poppins/poppins-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/poppins/poppins-v15-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-700italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: url('./fonts/poppins/poppins-v15-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/poppins/poppins-v15-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/poppins/poppins-v15-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/poppins/poppins-v15-latin-700italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-800 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/poppins/poppins-v15-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/poppins/poppins-v15-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/poppins/poppins-v15-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-800.woff') format('woff'), /* Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/poppins/poppins-v15-latin-800.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-800italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  src: url('./fonts/poppins/poppins-v15-latin-800italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/poppins/poppins-v15-latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/poppins/poppins-v15-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-800italic.woff') format('woff'), /* Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/poppins/poppins-v15-latin-800italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-900 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/poppins/poppins-v15-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/poppins/poppins-v15-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/poppins/poppins-v15-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-900.woff') format('woff'), /* Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/poppins/poppins-v15-latin-900.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-900italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  src: url('./fonts/poppins/poppins-v15-latin-900italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/poppins/poppins-v15-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/poppins/poppins-v15-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-900italic.woff') format('woff'), /* Modern Browsers */
       url('./fonts/poppins/poppins-v15-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/poppins/poppins-v15-latin-900italic.svg#Poppins') format('svg'); /* Legacy iOS */
}


/*
 * Helvetica neue font faces were generated by the
 * src/fonts/generageFontFaces.rb script. Use that to make updates.
 */


/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-Bd.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-expanded';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-UltLtEx.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue';
  font-style: italic;
  font-weight: 100;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-ThIt.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-condensed';
  font-style: oblique;
  font-weight: 400;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-LtCnO.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue';
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-Th.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue';
  font-style: italic;
  font-weight: 850;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-HvIt.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-condensed';
  font-style: oblique;
  font-weight: 200;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-UltLtCnO.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-condensed';
  font-style: oblique;
  font-weight: normal;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-CnO.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-expanded';
  font-style: oblique;
  font-weight: 950;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-BlkExO.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-expanded';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-BdEx.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-UltLt.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-expanded';
  font-style: oblique;
  font-weight: 850;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-HvExO.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-expanded';
  font-style: normal;
  font-weight: 950;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-BlkEx.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-expanded';
  font-style: oblique;
  font-weight: 700;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-BdExO.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-condensed';
  font-style: oblique;
  font-weight: 1050;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-XBlkCnO.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue';
  font-style: italic;
  font-weight: normal;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-It.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-condensed';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-UltLtCn.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-condensed';
  font-style: oblique;
  font-weight: 500;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-MdCnO.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-expanded';
  font-style: oblique;
  font-weight: 100;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-ThExO.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-condensed';
  font-style: normal;
  font-weight: 950;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-BlkCn.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue';
  font-style: italic;
  font-weight: 500;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-MdIt.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue';
  font-style: normal;
  font-weight: 850;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-Hv.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-condensed';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-BdCn.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue';
  font-style: italic;
  font-weight: 400;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-LtIt.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-condensed';
  font-style: normal;
  font-weight: 1050;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-XBlkCn.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-expanded';
  font-style: oblique;
  font-weight: 500;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-MdExO.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-expanded';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-MdEx.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-expanded';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-LtEx.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-outline';
  font-style: oblique;
  font-weight: 700;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-BdOu.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-condensed';
  font-style: oblique;
  font-weight: 100;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-ThCnO.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-expanded';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-Ex.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-condensed';
  font-style: normal;
  font-weight: 850;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-HvCn.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-condensed';
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-ThCn.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue';
  font-style: normal;
  font-weight: 950;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-Blk.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-condensed';
  font-style: oblique;
  font-weight: 950;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-BlkCnO.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-expanded';
  font-style: oblique;
  font-weight: normal;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-ExO.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue';
  font-style: italic;
  font-weight: 700;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-BdIt.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-condensed';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-LtCn.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-expanded';
  font-style: oblique;
  font-weight: 200;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-UltLtExO.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-expanded';
  font-style: oblique;
  font-weight: 400;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-LtExO.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-Roman.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-condensed';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-MdCn.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue';
  font-style: italic;
  font-weight: 950;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-BlkIt.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-Md.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue';
  font-style: italic;
  font-weight: 200;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-UltLtIt.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-expanded';
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-ThEx.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-condensed';
  font-style: oblique;
  font-weight: 700;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-BdCnO.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-expanded';
  font-style: normal;
  font-weight: 850;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-HvEx.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-condensed';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-Cn.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue-condensed';
  font-style: oblique;
  font-weight: 850;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-HvCnO.otf') format('truetype');
  descent-override: 0%;
}

/* generated by src/fonts/generageFontFaces.rb */
@font-face {
  font-family: 'helvetica-neue';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/helveticaNeue/HelveticaNeueLTStd-Lt.otf') format('truetype');
  descent-override: 0%;
}
